import React, { useState } from 'react';
import { Button, ButtonPriority, InputDialog, ThreeDotsLoader } from 'wix-ui-tpa';
import {
  useBi,
  useEnvironment,
  useErrorMonitor,
  useExperiments,
  useFedopsLogger,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import { getDetails, getLanguage, getPayNowModalSubscription, isPayNowModalOpen } from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget, PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { classes, st } from './PayNowModal.st.css';
import classNames from 'classnames';
import { Interactions } from '../../../../types/interactions';
import { aClickCancelUpmEvent, aClickSubmitUpmEvent } from '@wix/bi-logger-my-subscriptions/v2';
import { PageType } from '@wix/cashier-common/dist/src/enums/PageType';
import { getPrefilleFormFields } from '../../../../utils/UpmAndPayNowUtils';
import { PayNowFlowStatus } from '../../constants';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
interface Props {
  subscription: Subscription;
}
export const PayNowModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
}: RuntimeProps & Props) => {
  const { isMobile } = useEnvironment();
  // const biLogger = useBi();
  const [shouldDisableSubmitBtn, setShouldDisableSubmitBtn] = useState(false);
  const [isWidgetFullyMounted, setIsWidgetFullyMounted] = useState(false);
  const [payNowFlowStatus, setPayNowFlowStatus] = useState(PayNowFlowStatus.INIT);
  const paymentsWidgetApi = React.useRef<PaymentsWidgetAPI | undefined>();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { msid, instance, siteOwnerId, sessionId, visitorId } = IdentityParams;
  const fedopsLogger = useFedopsLogger();
  const errorMonitor = useErrorMonitor();
  const endInteraction = () => {
    fedopsLogger.interactionEnded(Interactions.SubscriptionPayNow);
    setPayNowFlowStatus(PayNowFlowStatus.INIT);
  };

  const PayNowModalSubmit = async () => {
    fedopsLogger.interactionStarted(Interactions.SubscriptionPayNow);
    if (payNowFlowStatus === PayNowFlowStatus.INIT) {
      setPayNowFlowStatus(PayNowFlowStatus.PENDING);
      if (!paymentsWidgetApi.current?.pay) {
        return; // TODO add error message
      }
      try {
        const result = await paymentsWidgetApi.current?.pay!();
        console.log('result', result);

        if (!result) {
          return;
        }
        const validateResponse = await paymentsWidgetApi.current.validate();

        if (validateResponse.isValid) {
          actionCreators.submitPayNow({ subscriptionId: subscription.id! });
          endInteraction();
        }
      } catch (e) {
        errorMonitor.captureMessage(`Payments Widget Crashed On Payn now ${subscription.id}`);
      }
    } else {
      fedopsLogger.interactionEnded(Interactions.SubscriptionPayNow);
    }
  };

  const onClickCloseModal = (referral: string) => {
    if (payNowFlowStatus !== PayNowFlowStatus.PENDING) {
      actionCreators.closePayNowModal();
    }
  };

  const getModalSubtitle = () => {
    return 'pay now modal subtitle placeholder';
  };

  return (
    <div className={classNames(st(classes.root, { desktop: !isMobile }))}>
      <InputDialog
        title="pay now title placeholder"
        subtitle={getModalSubtitle()}
        data-hook="pay-now-modal"
        isOpen={isOpen}
        onClose={() => {
          onClickCloseModal('exit_window');
        }}
        fullscreen={isMobile}
        customFooter={
          <div className={classes.modalFooter}>
            <Button
              onClick={PayNowModalSubmit}
              fullWidth={isMobile}
              data-hook="pay-now-submit-button"
              upgrade
              disabled={shouldDisableSubmitBtn || !isWidgetFullyMounted}
            >
              {payNowFlowStatus === PayNowFlowStatus.PENDING ? (
                <ThreeDotsLoader className={classes.threeDotsLoader} />
              ) : (
                t('app.checkout-form.pay-now-upm.submit.button')
              )}
            </Button>
            {!isMobile && (
              <Button
                onClick={() => onClickCloseModal('cancel_button')}
                data-hook="pay-now-cancel-button"
                priority={ButtonPriority.basicSecondary}
                disabled={payNowFlowStatus === PayNowFlowStatus.PENDING}
                upgrade
              >
                {t('app.checkout-form.pay-now-upm.cancel.button')}
              </Button>
            )}
          </div>
        }
      >
        <div
          className={classNames(classes.paymentsWidgetContainer, {
            [classes.pendingResponse]: payNowFlowStatus === PayNowFlowStatus.PENDING,
          })}
          data-hook="pay-now-payments-widget-container"
        >
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '5',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
              viewMode: 'Site',
              isSignedInUser: true,
              appSessionId: sessionId,
              snapshotId: 'c64cdc4a-fa4a-43ac-8cd9-4c03b7ce4375',
            }}
            externalSubmitButton // ability to show our own button
            onCrash={() => {
              setShouldDisableSubmitBtn(true);
              errorMonitor.captureMessage(`Payments Widget Crashed On Pay Now for subscripiton id ${subscription.id}`);
            }} // Payments will show error message,
            onApiInit={(api) => {
              paymentsWidgetApi.current = api;
            }}
            onFullLoad={() => {
              setIsWidgetFullyMounted(true);
            }}
            isSaveCCEnabled={true}
            pageType={PageType.PaymentComponent}
            mandatoryFieldsPrefilled={getPrefilleFormFields(subscription, experiments)}
          />
          <div className={classes.afterWidgetPadding} />
        </div>
      </InputDialog>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isPayNowModalOpen(state),
    subscription: getPayNowModalSubscription(state),
    actionCreators,
    language: getLanguage(state),
    IdentityParams: state.IdentityParams,
    subscriptionExtraDetails: getDetails(state, state.payNowModal.subscriptionId!),
  };
};

export default connect(mapRuntimeToProps)(PayNowModal);
